import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Fade } from "react-reveal";
import { data, data2, data3, data4 } from "./data";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "#000",
    marginTop: "4rem",
    marginBottom: "5rem",
  },
  main_grid: {
    padding: "1rem 0",
  },
  main_grid2: {
    padding: "1rem 0",
    flexWrap: "wrap-reverse",
  },

  main_grid4: {
    padding: "1rem 0",
    flexWrap: "wrap-reverse",
  },
  grid1: {
    display: "flex",
    justifyContent: "start",
    "@media (max-width: 955px)": {
      display: "flex",
      justifyContent: "center",
    },
  },

  grid2: {
    display: "flex",
    justifyContent: "end",
    "@media (max-width: 955px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  header: {
    textAlign: "start",
    color: "#fff",
    fontSize: "2rem",
  },
  heading2: {
    color: "#fff",
  },
  details: {
    color: "#fff",
    // fontSize: "1rem",
  },

  Title: {
    color: "#fff",
    textAlign: "center",
    margin: "2rem 0rem",
    fontSize: "3rem",
    letterSpacing: "2px",
  },

  img: {
    width: "70%",
    border: "2px solid #FEE715FF",
    "@media (max-width: 955px)": {
      width: "80%",
      marginTop: "1rem",
      border: "2px solid #FEE715FF",
    },
  },
}));
function MetaVerse() {
  const classes = useStyles();
  return (
    <div className={classes.root} id="utility">
      <h1 className={classes.Title}>UTILITY</h1>
      {/* FIST DATA GOES HERE, "Contractor Utilities" */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.main_grid}
      >
        {data.map((content) => (
          <>
            <Grid item xs={10} sm={10} md={5} lg={5} className={classes.grid1}>
              <Fade left>
                <div>
                  <h1 className={classes.header}>{content.heading}</h1>

                  <Typography variant="h6" className={classes.details}>
                    {content.details}
                    {content.details2}
                    {content.details3}
                  </Typography>
                </div>
              </Fade>
            </Grid>

            <Grid item xs={10} sm={10} md={5} lg={5} className={classes.grid2}>
              <Fade right>
                <img src={content.img} className={classes.img} />
              </Fade>
            </Grid>
          </>
        ))}
      </Grid>

      {/* SECOND DATA GOES HERE, "Exploring The Metaverse" */}

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.main_grid2}
      >
        {data2.map((content) => (
          <>
            <Grid item xs={10} sm={10} md={5} lg={5} className={classes.grid1}>
              <Fade left>
                <img src={content.img} className={classes.img} />
              </Fade>
            </Grid>

            <Grid item xs={10} sm={10} md={5} lg={5} className={classes.grid2}>
              <Fade right>
                <div>
                  <h1 className={classes.header}>{content.heading}</h1>
                  <h4 className={classes.heading2}>{content.heading2}</h4>

                  <Typography variant="h6" className={classes.details}>
                    {content.details}
                  </Typography>
                </div>
              </Fade>
            </Grid>
          </>
        ))}
      </Grid>

      {/* THIRED DATA GOES HERE, "Staking" */}

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.main_grid}
      >
        {data3.map((content) => (
          <>
            <Grid item xs={10} sm={10} md={5} lg={5} className={classes.grid1}>
              <Fade left>
                <div>
                  <h1 className={classes.header}>{content.heading}</h1>
                  <h4 className={classes.heading2}>{content.heading2}</h4>

                  <Typography variant="h6" className={classes.details}>
                    {content.details}
                  </Typography>
                </div>
              </Fade>
            </Grid>

            <Grid item xs={10} sm={10} md={5} lg={5} className={classes.grid2}>
              <Fade right>
                <img src={content.img} className={classes.img} />
              </Fade>
            </Grid>
          </>
        ))}
      </Grid>

      {/* FOURTH DATA GOES HERE, "Exclusive Training & Events" */}

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.main_grid4}
      >
        {data4.map((content) => (
          <>
            <Grid item xs={10} sm={10} md={5} lg={5} className={classes.grid1}>
              <Fade left>
                <img src={content.img} className={classes.img} />
              </Fade>
            </Grid>

            <Grid item xs={10} sm={10} md={5} lg={5} className={classes.grid2}>
              <Fade right>
                <div>
                  <h1 className={classes.header}>{content.heading}</h1>
                  <h4 className={classes.heading2}>{content.heading2}</h4>

                  <Typography variant="h6" className={classes.details}>
                    {content.details}
                  </Typography>
                </div>
              </Fade>
            </Grid>
          </>
        ))}
      </Grid>
    </div>
  );
}

export default MetaVerse;
