const data = [
  {
    heading: "Skilled Trade Professionals",
    img: "../Assets/mcurry2.jpg",
    details:
      "Each CryptoContractor is unique and hand designed with over 300 possible traits, including professional tools, OSHA standard hard hats, protective eyewear, safety gloves and more. All CryptoContractors are highly skilled in the real world and also in the Metaverse. The CryptoContractors are stored as ERC-721 tokens on the Ethereum blockchain and hosted on IPFS. (See Record and Proof.) Purchasing a CryptoContractor costs 0.25 - 1 ETH (pending crypto market) if you're on the whitelist. In order to access members-only areas such as “TBCC Accelerator Program",

    details2: <sup>TM</sup>,
    details3:
      ", CryptoContractor holders will need to be signed into their Metamask Wallet then access Collabland to verify themselves as a holder.",
  },
];

const data2 = [
  {
    heading: "Exploring The Metaverse:",
    img: "../Assets/mcurry.jpg",
    details:
      "CryptoContractors Club does not want to be known as a “here today gone tomorrow” NFT project. We want to be known as a project with amazing utilities that grows, evolves and adapts over time. We want to add value to our holders, community and partners. We will be purchasing land (Crypto Island) in the metaverse and will have our holders who are architects, engineers, project managers, designers and builders create their own buildings as in the Monopoly Builders board game.",
  },
];

const data3 = [
  {
    heading: "Staking:",

    img: "../Assets/mcurry3.jpg",
    details:
      "In phase 2 or Beyond we will unlock staking for NFT holders that will allow purchases from our ACE Hardware or True Value store located in Tampa FL. Details and Cryptocurrency regulations are still being implemented as this is a relatively new marketplace but we will keep the community updated throughout this process. CryptoContractor NFT holders will also gain full copyrights to use NFT as they wish after X amount of time staking their NFT.",
  },
];
const data4 = [
  {
    heading: "Exclusive Training & Events: ",

    img: "../Assets/mcurry4.jpg",
    details:
      "Our goal is to increase the value of each NFT along with each NFT holder by improving our skill set around our crafts. We will execute this vision by partnering with institutions that provide certification in each of our holders industries ie: Electrician, Plumbing, Water Restoration, etc.",
  },
];
export { data, data2, data3, data4 };
