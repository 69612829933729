import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import { Fade } from "react-reveal";

// Element styling starts here

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    background: "#000",
  },
  heading: {
    fontSize: "1.4rem",
    fontWeight: "bolder",
  },
  heading1: {
    fontWeight: "bolder",
    textAlign: "center",
    color: "#fff",
    marginBottom: "2rem",
    fontSize: "3rem",
    letterSpacing: "2px",
  },
  accord: {
    textAlign: "center",
    backgroundColor: "#DADADA",
    marginBottom: "1rem",
    border: "1px solid #000",
    borderRadius: "12px",
  },
  accordSummary: {
    background: "#DADADA",
    borderRadius: "12px",
    color: "#000",
    textAlign: "start",
    padding: "0.6rem 1rem",
    "&:hover": {
      background: "#FEE715FF",
    },
  },
  faqPara: {
    fontSize: "1.4rem",
  },
  faqIcon: {
    color: "#000",
  },
}));

// Element styling ends here

export default function SimpleAccordion() {
  const classes = useStyles();

  return (
    <div className={classes.root} id="Faq">
      <Fade left>
        <h1 className={classes.heading1}>FAQs</h1>
      </Fade>
      <Fade right>
        <Grid container justifyContent="center" className={classes.accordFAQ}>
          <Grid item xs={10} sm={10} md={9}>
            <Accordion className={classes.accord}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.faqIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className={classes.accordSummary}
              >
                <Typography className={classes.heading}>
                  What is an NFT?
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordSummary}>
                <Typography className={classes.faqPara}>
                  NFT is the abbreviation for “Non-Fungible Token”. It’s a
                  unique numeric asset which ownership is managed by the
                  blockchain and that the users may possess, buy and trade.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accord}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.faqIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className={classes.accordSummary}
              >
                <Typography className={classes.heading}>
                  What is Metamask?
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordSummary}>
                <Typography className={classes.faqPara}>
                  Metamask is a crypto wallet that can store your Ethereum.You
                  need it to buy “CryptoContractors Club NFT”
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accord}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.faqIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className={classes.accordSummary}
              >
                <Typography className={classes.heading}>
                  Which blockchain CryptoContractors Club On?
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordSummary}>
                <Typography className={classes.faqPara}>
                  CryptoContractors Club will live on the Ethereum Blockchain.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accord}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.faqIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className={classes.accordSummary}
              >
                <Typography className={classes.heading}>
                  How do I buy a CrypotContractors Club NFT?
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordSummary}>
                <Typography className={classes.faqPara}>
                  You can mint your CryptoContractors Club NFT on our official
                  website and on OpenSea. We’ll announce the exact day and hour
                  on our Discord. So make sure to join us from now on by
                  clicking on the Discord link above.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accord}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.faqIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className={classes.accordSummary}
              >
                <Typography className={classes.heading}>
                  How do I get on the whitelist?
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordSummary}>
                <Typography className={classes.faqPara}>
                  Join our discord! We have multiple ways to get on whitelist.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accord}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.faqIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className={classes.accordSummary}
              >
                <Typography className={classes.heading}>
                  How much is the CryptoContractors Club NFT?
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordSummary}>
                <Typography className={classes.faqPara}>
                  Whitelist: TBD Public: TBD
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accord}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.faqIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className={classes.accordSummary}
              >
                <Typography className={classes.heading}>
                  What is the total supply of CryptoContractors?{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordSummary}>
                <Typography className={classes.faqPara}>
                  The total supply is 10,000 Crypto Contractors.{" "}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accord}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.faqIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className={classes.accordSummary}
              >
                <Typography className={classes.heading}>
                  Why CryptoContractors Club?{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordSummary}>
                <Typography className={classes.faqPara}>
                  CryptoContractors Club was started due to a need to help
                  improve the Credit, Capital and CashFlow of highly skilled
                  trade professionals
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Fade>
    </div>
  );
}
