import React, { useState } from "react";
import {
  Drawer,
  Icon,
  Grid,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-scroll";
import MenuIcon from "@material-ui/icons/Menu";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import "./Drawer.css";
const useStyles = makeStyles(() => ({
  root: {
    height: "100vh",
  },
  link: {
    textDecoration: "none",
    color: "#fff",
    fontSize: "20px",
    padding: "1rem 5rem",
    cursor: "pointer",
    marginTop: "1rem",
    display: "block",
    textAlign: "center",
    fontFamily: "Yatra One",
  },
  headerIcons: {
    color: "#fff",
    // backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "3rem",
  },

  icons: {
    // margin: "0.5rem",
    color: "#fff",
    fontSize: "2rem",
  },
  discordBtn: {
    // backgroundColor: "#111111",
    color: "white",
    borderRadius: "10px",
    border: "2px solid white",
    // padding: "0.5rem",
  },

  icon: {
    color: "#fff",
    justifyContent: "flex-end",
  },
  // headerIcons: {
  //   marginTop: "3rem",
  // },

  logo_text: {
    fontFamily: "Yatra One",
  },

  header_div: {
    display: "flex",
    justifyContent: "space-between",
  },

  close_icon: {
    marginLeft: "4rem",
  },
}));

function DrawerComponents() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer, AnchorElNav] = useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(() => setOpenDrawer(false));
  };

  return (
    <div>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        className={classes.root}
      >
        <div className={classes.link}>
          <div className={classes.header_div}>
            <h3 className={classes.logo_text}>CRYPTOCONTRACTORS CLUB</h3>
            <CloseIcon
              className={classes.close_icon}
              onClick={handleCloseNavMenu}
            />
          </div>

          <Link
            to="about_us"
            spy={true}
            smooth={true}
            hashSpy={true}
            offset={0}
            duration={1000}
            className={classes.link}
            onClick={handleCloseNavMenu}
          >
            ABOUT US
          </Link>
          <Link
            to="traits"
            spy={true}
            smooth={true}
            hashSpy={true}
            offset={-45}
            duration={1000}
            isDynamic={true}
            className={classes.link}
            onClick={handleCloseNavMenu}
          >
            TRAITS
          </Link>
          <Link
            to="utility"
            spy={true}
            smooth={true}
            hashSpy={true}
            offset={-70}
            duration={1000}
            isDynamic={true}
            className={classes.link}
            onClick={handleCloseNavMenu}
          >
            UTILITY
          </Link>
          <Link
            to="Roadmap"
            spy={true}
            smooth={true}
            hashSpy={true}
            offset={-45}
            duration={1000}
            isDynamic={true}
            className={classes.link}
            onClick={handleCloseNavMenu}
          >
            ROADMAP
          </Link>

          <Link
            to="Team"
            spy={true}
            smooth={true}
            hashSpy={true}
            offset={-45}
            duration={1000}
            isDynamic={true}
            className={classes.link}
            onClick={handleCloseNavMenu}
          >
            TEAM
          </Link>

          <Link
            to="Faq"
            spy={true}
            smooth={true}
            hashSpy={true}
            offset={-45}
            duration={1000}
            isDynamic={true}
            className={classes.link}
            onClick={handleCloseNavMenu}
          >
            FAQ
          </Link>

          <Grid sm={10} md={12} lg={12} className={classes.headerIcons}>
            <Button
              varint="contained"
              href="https://discord.com/invite/9Bx8TpDg"
              className={classes.discordBtn}
            >
              Discord
              <a href="https://discord.com/invite/9Bx8TpDg"></a>
            </Button>
            <a
              href="https://www.facebook.com/CryptoContractorsClub/"
              target="_blank"
            >
              <FacebookIcon className={classes.icons} />{" "}
            </a>
            <a
              href="https://www.instagram.com/cryptocontractorsclub/"
              target="_blank"
            >
              <InstagramIcon className={classes.icons} />
            </a>
            <a href="https://twitter.com/MrCCClub" target="_blank">
              <TwitterIcon className={classes.icons} />
            </a>
          </Grid>
        </div>
      </Drawer>
      <IconButton
        onClick={() => setOpenDrawer(!openDrawer)}
        className={classes.icon}
      >
        <MenuIcon />
      </IconButton>
    </div>
  );
}

export default DrawerComponents;
