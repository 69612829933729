import { Grid, Typography, makeStyles } from "@material-ui/core";

import React from "react";
import { Fade } from "react-reveal";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "4rem",
    paddingBottom: "4rem",
    background: "#fff",
  },
  main_grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  grid1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  details: {
    color: "#000",
  },
  aboutUsHeader: {
    color: "#000",
  },

  aboutUsGIF: {
    width: "450px",
    marginTop: "1rem",
    border: "2px solid #FEE715FF",
    "@media (max-width: 955px)": {
      width: "80%",
      marginTop: "1rem",
      border: "2px solid #FEE715FF",
    },
  },
}));
function AboutUs() {
  const classes = useStyles();
  return (
    <div className={classes.root} id="about_us">
      <Fade right>
        <Grid container className={classes.main_grid}>
          <Grid item xs={10} sm={10} md={10} lg={5} className={classes.grid1}>
            <div>
              <h1 className={classes.aboutUsHeader}>
                WELCOME TO THE CRYPTOCONTRACTORS CLUB
              </h1>
              <Typography variant="h5" className={classes.details}>
                CryptoContractors Club is a collection of 10,000
                CryptoContractors Club NFTs—unique digital collectibles with
                utility living on the Ethereum blockchain. Your CryptoContractor
                serves as access to our Business Credit Accelerator Program; a 6
                week course designed to help Contractors and Water Restoration
                Technicians properly structure their business and get approved
                for 6-7 figures in business credit, capital, and cash-flow.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={10} sm={10} md={10} lg={5} className={classes.grid1}>
            <img
              src="../Assets/3144.gif"
              alt="about US "
              className={classes.aboutUsGIF}
            />
          </Grid>
        </Grid>
      </Fade>
    </div>
  );
}

export default AboutUs;
