const initialState = {
  loading: false,
  maxSupply: 0,
  cost: "",
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        cost: action.payload.cost,
        preSaleCost: action.payload.preSaleCost,
        preSaleDate: action.payload.preSaleDate,
        publicSaleDate: action.payload.publicSaleDate,
        maxSupply: action.payload.maxSupply,
        // presaleMint: action.payload.presaleMint,
        // mint: action.payload.mint,
        preSaleEndDate: action.payload.preSaleEndDate,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
