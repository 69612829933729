import "./RoadMap.css";

import timelineElements from "./timelineElements";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";

function App() {
  let workIconStyles = { background: "#FEE715FF" };
  let schoolIconStyles = { background: "#552E10" };

  return (
    <div className="root" id="Roadmap">
      <h1 className="title">ROAD MAP</h1>
      <VerticalTimeline>
        {timelineElements.map((element) => {
          let isWorkIcon = element.icon === "work";

          return (
            <VerticalTimelineElement
              key={element.key}
              iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
              icon={element.percent}
              className="percent"
            >
              <h1 className="phase">{element.phase}</h1>
              <h2 className="element-title">{element.title}</h2>
              <h5 className="element-subtitle">{element.location}</h5>
              <p id="description">{element.description}</p>
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </div>
  );
}

export default App;
