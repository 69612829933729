import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
const data = [
  {
    imgsrc: "../Assets/Mustafa.png",
    headTitle: "Mustafa ",
    subTitle:
      " Founder // Business Banker // Certified General Contractor AKA CryptoContractor // Mr. CryptoContractor",
    subPara:
      "Mustafa was a credit manager, personal and business banker at Wells Fargo for 10 years. Currently a business partner of Dry 24 Water Restoration. Business Consultant. ",

    icon: <InstagramIcon />,
    liIcon: <LinkedInIcon />,
    links: "https://www.instagram.com/mustafacurry/",
    linkdin: "https://www.linkedin.com/in/mustafa-curry-73607094",
  },
  {
    imgsrc: "../Assets/Chris.png",
    headTitle: "Chris",
    subTitle:
      " Master Water Restorer // Owner of Dry 24 Water Restoration AKA CryptoContractor // Get The Job Done",
    subPara:
      "Chris is the owner of Dry 24 Water Restoration. IICRC Certified in over 10 areas including Commercial Drying Specialist, Water Damage Restoration, Applied Microbial Remediation, Health & Safety",
    liIcon: <LinkedInIcon />,
    linkdin: " https://www.linkedin.com/in/christophercunninghamelitepro/",
  },
  {
    imgsrc: "../Assets/c88.png",
    headTitle: "Kashif",
    subTitle: " Web 3 Developer // Contract Developer // Graphic Designer",
    subPara:
      "Kashif is a brilliant developer, who is quick-witted, and quick to learn and implement ideas across various programming languages. He brings a wealth of experience spanning 5 years in the blockchain space.",
  },
  {
    imgsrc: "../Assets/c33.png",
    headTitle: "Rida ",
    subTitle: " Illustration Artist // AKA CryptoContractor // RidaFatima",
    subPara:
      "Rida is a professional graphic designer with over 7 years of experience working with marketing agencies and in-house marketing departments. Handmade designs and illustrations. Provides quick, efficient and sophisticated high quality service.",

    liIcon: <LinkedInIcon />,
    whatsAppIcon: <WhatsAppIcon />,
    whatsAppLink: "https://wa.me/+923408360122",
    linkdin: " https://www.linkedin.com/in/rida-fatima-77a4b8195",
  },
  {
    imgsrc: "../Assets/309.png",
    headTitle: "Shaeed",
    subTitle:
      " Community Manager // Collaboration Manager // AKA CryptoContractor // Boolean",
    subPara:
      "Saheed is an experienced project manager and marketing specialist with over 3 years of extensive knowledge in the Defi sector. Saheed is our Discord Server Moderator, NFT and Metaverse manager.",
    // icon: <InstagramIcon />,
    // liIcon: <LinkedInIcon />,
  },
];

const contentData = [
  {
    heading: "OUR TEAM",
    headDes:
      "The Brand was created by a team of ‘CryptoContractors Club’: Skilled Trade Professionals, Former Business Bankers, Entrepreneurs, Blockchain Experts, Marketing Wizards, And Artists...",
  },
];

export { data, contentData };
