import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import fromExponential from "from-exponential";
import { Button, makeStyles } from "@material-ui/core";
const { MerkleTree } = require("merkletreejs");
const keccak256 = require("keccak256");

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundSize: "cover",
    display: "flex",
    alignItems: "flex-end",
    height: "100vh",
    marginTop: "1rem",
    justifyContent: "center",
    backgroundImage: `url(${
      process.env.PUBLIC_URL + "/Assets/construction33.png"
    })`,
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    width: "99vw",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  btn: {
    padding: "1rem 4rem",
    fontSize: "bold",
    textTransform: "uppercase",
  
    background: "#FEE715FF",
    marginBottom: "5rem",
    border: "2px  solid #1CB5E0",
    borderRadius: "5px",
    color: "#000",
  },
}));

const whiteListAddresses = [
  "0x8b0eF90C71ed8b18786AaB55B0d41cE093724fA6",
  "0x40E5c1f59E3308613E44C314abbfa1dD8FdC0606",
  "0x848b6B2e7c8Bf9D4172305fd12B51446417e6f9a",
  "0x01C6902654Ebbf0270F09AdF6B03C1c0919e1BB4",
  "0x0E4A3D1D6800e99b8EE13be9686144Ff2eF0204d",
];

function Home() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  console.log("blockchain", blockchain);
  const data = useSelector((state) => state.data);
  console.log("data=>", data);
  const [mint, setMint] = useState("");
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`connect your wallet and mint`);
  const [mintAmount, setMintAmount] = useState(1);
  // CONFIGRATION FILE INITIAL VALUES
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  console.log("CONTRACT ADDRESS", CONFIG.CONTRACT_ADDRESS);

  //CLAIM NFT FUNCTION
  const claimNFTs = () => {
    const leafNodes = whiteListAddresses.map((addr) => keccak256(addr));
    const merkleTree = new MerkleTree(leafNodes, keccak256, {
      sortPairs: true,
    });

    const rootHash = merkleTree.getRoot();

    const claimingAddress = keccak256(blockchain.account);

    const hexProof = merkleTree.getHexProof(claimingAddress);

    let cost = Number(data.cost);
    let preSaleCost = Number(data.preSaleCost);
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = cost * mintAmount;
    let totalCostWeiPreSale = preSaleCost * mintAmount;
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Total WEICost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    console.log("minting date", mint);
    const costFinal = String(fromExponential(totalCostWei));
    const preSaleCostFinal = String(fromExponential(totalCostWeiPreSale));
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    // eslint-disable-next-line no-lone-blocks
    {
      mint == "presale"
        ? blockchain.smartContract.methods
            // PRESALE MINT
            .presaleMint(mintAmount, hexProof)
            .send({
              gasLimit: totalGasLimit,
              to: CONFIG.CONTRACT_ADDRESS,
              from: blockchain.account,
              value: preSaleCostFinal,
            })
            .once("error", (err) => {
              console.log(err);
              setFeedback(
                "Sorry, something went wrong please try again later."
              );
              setClaimingNft(false);
            })
            .then((receipt) => {
              console.log(receipt);
              setFeedback(
                `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
              );
              setClaimingNft(false);
              dispatch(fetchData(blockchain.account));
            })
        : blockchain.smartContract.methods
            // PUBLIC MINT
            .mint(mintAmount)
            .send({
              gasLimit: totalGasLimit,
              to: CONFIG.CONTRACT_ADDRESS,
              from: blockchain.account,
              value: costFinal,
            })
            .once("error", (err) => {
              console.log(err);
              setFeedback(
                "Sorry, something went wrong please try again later."
              );
              setClaimingNft(false);
            })
            .then((receipt) => {
              console.log(receipt);
              setFeedback(
                `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
              );
              setClaimingNft(false);
              dispatch(fetchData(blockchain.account));
            });
    }
  };
  // INCREMENT MINT AMOUNT FUNCTION
  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  // DECREMENT MINT AMOUNT FUNCTION
  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      console.log("dispatch called...");

      dispatch(fetchData(blockchain.account));
    }
  };

  // CONFIG FILE FUNCTION
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);
  //

  useEffect(() => {
    getData();
  }, [blockchain.account]);
  useEffect(() => {
    const currentTime = Date.now();
    if (
      currentTime.toString() <= data.preSaleEndDate &&
      currentTime.toString() > data.preSaleDate
    ) {
      setMint("presale");
    } else {
      setMint("public_sale");
    }
  }, [data]);
  return (
    <div className={classes.root} id="home">
      <div className={classes.btnContainer}>
        {data.cost !== "" ? (
          // FIRST CONDITION
          <>
            {claimingNft ? (
              <>
                <p className={classes.btn}>minting . . .</p>
              </>
            ) : (
              <div className={classes.minting_div}>
                <div className={classes.mintingBtns}>
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.btn}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      decrementMintAmount();
                    }}
                  >
                    -
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.btn}
                    // disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      claimNFTs();
                      getData();
                    }}
                  >
                    {claimingNft ? "minting" : "mint "}
                    {mintAmount}
                  </Button>

                  <Button
                    variant="contained"
                    size="large"
                    className={classes.btn}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      incrementMintAmount();
                    }}
                  >
                    +
                  </Button>
                </div>
              </div>
            )}
          </>
        ) : (
          // SECOND CONDITION
          <>
            {blockchain.account === "" || blockchain.smartContract === null ? (
              // NESTED FIRST CONDITION
              <>
                <div className={classes.mintingBtnTwo}>
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.btn}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                      getData();
                    }}
                  >
                    CONNECT WALLET
                  </Button>
                </div>
              </>
            ) : (
              // NESTED SECOND CONDITION
              <Button variant="contained" size="large" className={classes.btn}>
                CONNECTING . . .
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Home;
