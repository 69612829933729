import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-scroll";
import { Fade } from "react-reveal";
import pdf from "./Images/Disclaimer.pdf";
import Pdf from "./Images/Cookies.pdf";
import pDf from "./Images/Privacy.pdf";
import pdF from "./Images/Terms.pdf";

const useStyles = makeStyles(() => ({
  rootContainer: {
    backgroundColor: "#000",
    paddingBottom: "2rem",
    paddingTop: "9rem",
  },
  footer_logo: {
    color: "#fff",
    margin: "0",
    fontSize: "1.2rem",
    // textTransform: "uppercase",
  },
  footer_ul: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
  },
  footer_li: {
    color: "#fff",
    padding: "5px 0",
    textTransform: "uppercase",
    cursor: "pointer",
  },
  pages_heading: {
    color: "#fff",
    margin: "0",
    paddingLeft: "6px",
    fontSize: "1.2rem",
    textTransform: "uppercase",
  },

  Social_heading: {
    color: "#fff",
    margin: "0",
    paddingRight: "20px",
    fontSize: "1.2rem",
    textTransform: "uppercase",
  },
  policy_link: {
    color: "#fff",
    textDecoration: "none",
  },
  pdfLinks: {
    fontSize: "16px",
    textTransform: "uppercase",
    backgroundColor: "black",
    color: "white",
    borderRadius: "0rem",
    border: "none",
    cursor: "pointer",
  },
}));

function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.rootContainer}>
      <Fade left>
        <Grid container justifyContent="center">
          <Grid item lg={9}>
            {/* Nested grid */}
            <Grid
              container
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Grid item lg={3}>
                <h1 className={classes.footer_logo}>CryptoContractors Club</h1>
              </Grid>
              <Grid item lg={3}>
                <h1 className={classes.pages_heading}>pages</h1>
                <ul className={classes.footer_ul}>
                  <li className={classes.footer_li}>
                    <a
                      className={classes.pdfLinks}
                      href={pdf}
                      without
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <button className={classes.pdfLinks} label="Resume">
                        Discalimer
                      </button>
                    </a>
                  </li>
                  <li className={classes.footer_li}>
                    <a
                      className={classes.pdfLinks}
                      href={Pdf}
                      without
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <button className={classes.pdfLinks} label="Resume">
                        Cookies Policy
                      </button>
                    </a>
                  </li>
                  <li className={classes.footer_li}>
                    <a
                      className={classes.pdfLinks}
                      href={pDf}
                      without
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <button className={classes.pdfLinks} label="Resume">
                        Privacy Policy
                      </button>
                    </a>
                  </li>
                  <li className={classes.footer_li}>
                    <a
                      className={classes.pdfLinks}
                      href={pdF}
                      without
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <button className={classes.pdfLinks} label="Resume">
                        Terms and Condition
                      </button>
                    </a>
                  </li>
                </ul>
              </Grid>
              <Grid item lg={3}>
                <h1 className={classes.Social_heading}>social media</h1>
                <ul className={classes.footer_ul}>
                  <li className={classes.footer_li}>
                    <a
                      href="https://discord.gg/4BcemMX6"
                      target="_blank"
                      className={classes.policy_link}
                    >
                      discord
                    </a>
                  </li>
                  <li className={classes.footer_li}>
                    <a
                      href="https://twitter.com/MrCCClub"
                      target="_blank"
                      className={classes.policy_link}
                    >
                      twitter
                    </a>
                  </li>
                  <li className={classes.footer_li}>
                    <a
                      href="https://t.me/+xK8y_AgTHcU4YmMx"
                      className={classes.policy_link}
                    >
                      telegram
                    </a>
                  </li>
                  <li className={classes.footer_li}>
                    <a
                      href="https://www.facebook.com/CryptoContractorsClub/"
                      target="_blank"
                      className={classes.policy_link}
                    >
                      facebook
                    </a>
                  </li>
                  <li className={classes.footer_li}>
                    <a
                      href="https://www.instagram.com/cryptocontractorsclub/"
                      target="_blank"
                      className={classes.policy_link}
                    >
                      instagram
                    </a>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    </div>
  );
}

export default Footer;
