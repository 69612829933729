let timelineElements = [
  {
    id: 1,
    percent: "1%",

    phase: "Phase 1",
    title: "CryptoContractors Club Launch",
    description:
      "Welcome to the CryptoContractors Club! We are launched and ready to rise to the top of the Ethereum Blockchain with our Contractors and find amazing projects and partnerships to invest into and grow our community!",
    icon: "work",
  },
  {
    id: 2,
    percent: "10%",

    title: "VIP Sale:",
    description:
      "A VIP sale will be launched for our amazing community members who have shown loyalty like no others, investors and some influencers that will help the project reach our goals.100% of community funds made from VIP sales will be used for marketing, if necessary",
    icon: "work",
  },
  {
    id: 3,
    percent: "25%",

    title: "Marketing Push",
    description:
      "As stated before we have held 75 CryptoContractors to issue to our VIP, Partners and Sponsorship in exchange for funding. Funds will be used in our marketing strategy to promote projects and bring in as many skilled tradesmen as possible! We will be reaching out to notable instagram accounts, influencers, construction companies, construction based podcasts and more. We are also looking for a few celebrities that will be JOINING the project, not just promoting it.",
    icon: "work",
  },
  {
    id: 4,
    percent: "30%",

    title: "Phase 1 Whitelist/Public Sale",
    description:
      "At this point we will be opening up the sale for our whitelisted members, who have met invite criteria, leveled up through awesome community engagement, whitelist giveaways, discord games and contests",
    icon: "work",
  },
  {
    id: 5,
    phase: "Phase 2 Launch",
    percent: "50%",

    description:
      "We have closed our Phase 1 sale and are launching our second phase of the project. All holders from phase 1 will be entered into our first NFT giveaway (valued around 1 eth) and perks of our first virtual event.",

    icon: "work",
  },
  {
    id: 6,
    percent: "60%",

    title: "Phase 2 Marketing Push",
    description:
      "We got this far with the help of our incredible community, our partners and sponsors. It’s our turn to show everyone we’re not letting off the gas pedal. We will invest more into marketing before,  public sale to ensure we sell out and everyone benefits together, if necessary. When we grow, everyone grows We will also be launching our twitter space series about high quality in demand skilled trade professionals.",

    icon: "work",
  },
  {
    id: 7,
    percent: "75%",

    title: "Launch Phase 2:",
    description:
      "CryptoContractors To The Rescue!!! It’s time for everyone to have an opportunity to purchase in Phase 2. We will not rush to this sale. We are working on having this phase sell out and get the contractors in the best wallets that will benefit the entire community. We will continue pushing marketing, doing twitter raids, instagram contests, AMA’s and so much more to build hype and inform all skilled trade professionals looking to be in the NFT space.",

    icon: "work",
  },
  {
    id: 7,
    percent: "85%",

    title: "Half Way Through Public Sale:",
    description:
      "When we reach 50% of our public sale launch, we will be adding an additional 15 ETH to our community fund to continue growing the project. The Community Fund stands at 25 ETH.",
    icon: "work",
  },
  {
    id: 7,
    percent: "100%",

    title: "We’ve Sold Out!!! ",
    description:
      "Amazing!!! We Are Sold Out!! Dedicate $25,000 To Trade Certification for All CryptoContractor holders We will increase our value and income together.",
    icon: "work",
  },
];

export default timelineElements;
