import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";

import {
  Button,
  makeStyles,
  useMediaQuery,
  useTheme,
  Grid,
} from "@material-ui/core";

import "./Header.css";
import brand from "../Images/cclogo.png";

import { Link } from "react-scroll";
import DrawerComponents from "./Drawer";

const useStyles = makeStyles((theme) => ({
  paper: {
    background: "none",
  },

  containers: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    padding: "1.2rem 0",
  },
  navLinks: {
    color: "white",
    textTransform: "uppercase",
    cursor: "pointer",
    padding: "0.5rem",
  },
  logo_grid: {
    display: "flex",
    alignItems: "center",
  },

  menu: {
    textTransform: "uppercase",
    textAlign: "right",
  },

  appbar: {
    backgroundColor: "#111",
  },

  logo_img: {
    width: "50%",
  },

  root: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    backgroundColor: "#111",
  },

  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    minHeight: "100vh",
    width: "99vw",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  btn: {
    padding: "0.5rem 1rem",
    fontSize: "bold",
    textTransform: "uppercase",
    background: "linear-gradient(267.37deg, #F5FF81 17.34%, #FF67DE 107.26%)",
    borderRadius: "5rem",
    color: "#111",
  },

  headerIcons: {
    color: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  icons: {
    margin: "0.3rem",
    color: "#fff",
    fontSize: "23px",
  },

  discordBtn: {
    backgroundColor: "#111111",
    color: "white",
    borderRadius: "20px",
    border: "2px solid white",
    padding: "0.5rem",
  },

  link: {
    fontSize: "18px",
    margin: "1rem",
    cursor: "pointer",
  },

  land_map_btn: {
    display: "flex",
    justifyContent: "end",
  },
}));
function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appbar}>
        <Toolbar className={classes.containers}>
          <Grid container alignItems="center" justifyContent="space-around">
            <Grid
              item
              xs={4}
              sm={4}
              md={2}
              lg={2}
              className={classes.logo_grid}
            >
              <Link
                to="home"
                spy={true}
                smooth={true}
                hashSpy={true}
                offset={-30}
                duration={1000}
                className={classes.link}
              >
                {/* <img src={brand} className={classes.logo_img} /> */}
              </Link>
              <img src={brand} className={classes.logo_img} />
            </Grid>

            {isMobile ? (
              <DrawerComponents />
            ) : (
              <>
                <Grid item md={7} lg={6}>
                  <div className={classes.menu}>
                    <Link
                      to="about_us"
                      spy={true}
                      smooth={true}
                      hashSpy={true}
                      offset={0}
                      duration={1000}
                      className={classes.link}
                    >
                      About Us
                    </Link>
                    <Link
                      to="traits"
                      spy={true}
                      smooth={true}
                      hashSpy={true}
                      offset={-45}
                      duration={1000}
                      isDynamic={true}
                      className={classes.link}
                    >
                      Traits
                    </Link>

                    <Link
                      to="utility"
                      spy={true}
                      smooth={true}
                      hashSpy={true}
                      offset={-70}
                      duration={1000}
                      isDynamic={true}
                      className={classes.link}
                    >
                      UTILITY
                    </Link>

                    <Link
                      to="Roadmap"
                      spy={true}
                      smooth={true}
                      hashSpy={true}
                      offset={-42}
                      duration={1000}
                      isDynamic={true}
                      className={classes.link}
                    >
                      Road-Map
                    </Link>

                    <Link
                      to="Team"
                      spy={true}
                      smooth={true}
                      hashSpy={true}
                      offset={-45}
                      duration={1000}
                      isDynamic={true}
                      className={classes.link}
                    >
                      Team
                    </Link>

                    <Link
                      to="Faq"
                      spy={true}
                      smooth={true}
                      hashSpy={true}
                      offset={-45}
                      duration={1000}
                      isDynamic={true}
                      className={classes.link}
                    >
                      FAQ
                    </Link>
                  </div>
                </Grid>
                <Grid md={3} lg={2} className={classes.headerIcons}>
                  <Button
                    varint="contained"
                    href="https://discord.gg/4BcemMX6"
                    className={classes.discordBtn}
                  >
                    Discord
                    <a href="https://discord.com/invite/9Bx8TpDg"></a>
                  </Button>
                  <a
                    href="https://www.facebook.com/CryptoContractorsClub/"
                    target="_blank"
                  >
                    <FacebookIcon className={classes.icons} />{" "}
                  </a>

                  <a
                    href="https://www.instagram.com/cryptocontractorsclub/"
                    target="_blank"
                  >
                    <InstagramIcon className={classes.icons} />
                  </a>

                  <a href="https://twitter.com/MrCCClub" target="_blank">
                    <TwitterIcon className={classes.icons} />
                  </a>

                  <a href=" https://t.me/+xK8y_AgTHcU4YmMx" target="_blank">
                    <TelegramIcon className={classes.icons} />
                  </a>
                </Grid>
              </>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
export default Header;
