import { CssBaseline } from "@material-ui/core";
import "./App.css";
import Header from "./Components/Header/Header";
import AboutUs from "./Components/AboutUs";
import ContinousSlider from "./Components/Slider/ContinousSlider";
import RoadMap from "./Components/Roadmap/RoadMap";
import TeamStarter from "./Components/Team/TeamStarter";
import Faq from "./Components/Faq";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import MetaVerse from "./Components/MetaVerse/MetaVerse";

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <Header />
      <Home />
      <AboutUs />
      <ContinousSlider />
      <MetaVerse />
      <RoadMap />
      <TeamStarter />
      <Faq />
      <Footer />
    </div>
  );
}

export default App;
