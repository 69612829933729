import { Container, Grid, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { Fade } from "react-reveal";
import { data, contentData } from "./data";

// ELEMENT STYLING STARTS HERE

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#000",

    padding: "5rem 0rem",
  },

  imgSec: {
    border: "3px solid #FEE715FF",
    borderRadius: "1.3rem",
    height: "100%",
    width: "100%",
  },

  teamHeader: {
    textTransform: "uppercase",
    color: "#fff",
    fontWeight: "bold",
    paddingBottom: "2rem",
    fontSize: "3rem",
    letterSpacing: "2px",
  },

  headerPara: {
    color: "#fff",
    paddingBottom: "3rem",
    fontSize: "1.5rem",
    width: "70%",
    textAlign: "center",
    margin: "0 auto",
  },

  iconSec: {
    color: "White",
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center'
  },
  iconLink1: {
    color: "#fff",
  },
  whatsAppDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },

  boxContent: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0",
  },

  imgHeader: {
    textTransform: "uppercase",
    color: "white",
    fontWeight: "bold",
    fontSize: "1.5rem",
  },

  imgDes: {
    color: "#DADADA",
    textTransform: "capitalize",
    fontSize: "1.1rem",
  },

  container1: {
    paddingBottom: "2rem",
  },
  iconLink: {
    color: "#fff",
    padding: "0 5px ",
  },
  spanPara: {
    color: "#94969D",
    fontSize: "1.3rem",
  },

  imgPara: {
    color: "#FEE832",
  },
}));

// ELEMENT STYLING ENDS HERE

function TeamStarter() {
  const classes = useStyles();

  return (
    <div className={classes.root} id="Team">
      <Container>
        {contentData.map((content) => (
          <Fade left>
            <div>
              <Typography
                className={classes.teamHeader}
                variant="h2"
                align="center"
              >
                {content.heading}
              </Typography>
              <Typography className={classes.headerPara}>
                {content.headDes}
                <span className={classes.spanPara}>{content.spanPara}</span>
              </Typography>
            </div>
          </Fade>
        ))}

        <Fade right>
          <div className={classes.imgBox}>
            <Grid
              container
              className={classes.container1}
              justifyContent="space-between"
              spacing={4}
            >
              {data.map((content) => (
                <Grid item xs={6} md={4} lg={4}>
                  <div>
                    <img
                      className={classes.imgSec}
                      src={content.imgsrc}
                      alt=""
                    />

                    <Box className={classes.boxContent}>
                      <div>
                        <Typography className={classes.imgHeader}>
                          {content.headTitle}
                        </Typography>

                        <Typography className={classes.imgDes}>
                          {content.subTitle}
                        </Typography>

                        <Typography className={classes.imgPara}>
                          {content.subPara}
                        </Typography>
                      </div>

                      <div className={classes.iconSec}>
                        <a
                          className={classes.iconLink1}
                          href={content.links}
                          target="_blank"
                        >
                          {content.icon}
                        </a>
                        <a
                          className={classes.iconLink}
                          href={content.linkdin}
                          target="_blank"
                        >
                          {content.liIcon}
                        </a>
                        <a
                          className={classes.iconLink}
                          href={content.whatsAppLink}
                          target="_blank"
                        >
                          {content.whatsAppIcon}
                        </a>
                      </div>
                    </Box>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </Fade>
      </Container>
    </div>
  );
}

export default TeamStarter;
