import React from "react";
import "./styles.css";

function ContinousSlider() {
  return (
    <>
      <div>
        <h1
          style={{
            fontWeight: "bolder",
            textAlign: "center",
            color: "#fff",
            paddingTop: "4rem",
            fontSize: "3rem",
            letterSpacing: "2px",
          }}
        >
          TRAITS
        </h1>
      </div>
      <div className="main-div" id="traits">
        {/* <Fade left>
          <h1>TRAITS</h1>
        </Fade> */}
        <article>
          <div>
            <ul>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/c1.png"
                  width={250}
                />
              </li>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/c1.png"
                  width={250}
                />
              </li>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/c2.png"
                  width={250}
                />
              </li>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/c3.png"
                  width={250}
                />
              </li>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/c4.png"
                  width={250}
                />
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/c5.png"
                  width={250}
                />
              </li>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/c2.png"
                  width={250}
                />
              </li>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/c7.png"
                  width={250}
                />
              </li>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/c8.png"
                  width={250}
                />
              </li>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/c11.png"
                  width={250}
                />
              </li>
            </ul>
          </div>
        </article>
      </div>
      {/* --------------------------------------------------------------- */}
      <div className="main-div_2" id="traits">
        <article className="article_two">
          <div>
            <ul>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/305.png"
                  width={250}
                />
              </li>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/306.png"
                  width={250}
                />
              </li>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/307.png"
                  width={250}
                />
              </li>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/308.png"
                  width={250}
                />
              </li>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/309.png"
                  width={250}
                />
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/310.png"
                  width={250}
                />
              </li>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/311.png"
                  width={250}
                />
              </li>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/312.png"
                  width={250}
                />
              </li>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/313.png"
                  width={250}
                />
              </li>
              <li>
                <img
                  className="SliderImgs"
                  src="../Assets/314.png"
                  width={250}
                />
              </li>
            </ul>{" "}
          </div>
        </article>
      </div>
    </>
  );
}

export default ContinousSlider;
