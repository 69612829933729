// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let cost = await store
        .getState()
        .blockchain.smartContract.methods.cost()
        .call();
      let preSaleCost = await store
        .getState()
        .blockchain.smartContract.methods.preSaleCost()
        .call();
      let preSaleDate = await store
        .getState()
        .blockchain.smartContract.methods.preSaleDate()
        .call();
      let publicSaleDate = await store
        .getState()
        .blockchain.smartContract.methods.publicSaleDate()
        .call();
      let maxSupply = await store
        .getState()
        .blockchain.smartContract.methods.maxSupply()
        .call();
      // let presaleMint = await store
      //   .getState()
      //   .blockchain.smartContract.methods.presaleMint()
      //   .call();
      // let mint = await store
      //   .getState()
      //   .blockchain.smartContract.methods.mint()
      //   .call();
      let preSaleEndDate = await store
        .getState()
        .blockchain.smartContract.methods.preSaleEndDate()
        .call();

      dispatch(
        fetchDataSuccess({
          cost,
          preSaleCost,
          preSaleDate,
          publicSaleDate,
          preSaleEndDate,
          maxSupply,
          // presaleMint,
          // mint,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
